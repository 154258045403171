import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Utilities
import TableFunctions from '../../utilities/table-functions';
import DataTable from 'react-data-table-component';

// Components
import UserSelect from '../global/user-select';
import DateField from '../global/date-field';

// Material Components
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { format } from 'date-fns-jalali';

// Confirmation Modal
import ConfirmationModal from '../global/confirmation-modal';

const LogsTable = ({ logs, taskId, triggerReload }) => {

    const [selectedLog, setSelectedLog] = useState({
        'message': '',
        'time_worked': null
    });
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        if( !open ){
            setSelectedLog({
                'message': '',
                'time_worked': null
            });
        }
    }, [open]);

    const rowClick = ( row ) => {
        setSelectedLog(row);
        setOpen(true);
    }

    const saveLog = (e) => {
        if( selectedLog.id ){
            updateLog();
        }
        else{
            createLog();
        }    
    }

    const updateLog = () => {
        axios.post(`/log/${selectedLog.id}/update`, selectedLog)
        .then(response => {
            setOpen(false);
            triggerReload();
        })
        .catch(error => {
            console.log(error);
        });
    }

    const createLog = () => {
        const log = {
            ...selectedLog,
            task_id: taskId
        }
        axios.post(`/log/create`, log)
            .then(response => {
                setOpen(false);
                triggerReload();
            })
            .catch(error => {
                console.log(error);
            });
    }

    const confirmDelete = (row) => {
        setSelectedLog(row);
        setConfirmOpen(true);
    }

    const cancelDelete = () => {
        setSelectedLog({
            'message': '',
            'time_worked': null
        });
        setConfirmOpen(false);
    }

    const deleteLog = () => {
        axios.post(`/log/${selectedLog.id}/delete`)
            .then(response => {
                setConfirmOpen(false);
                triggerReload();
            })
            .catch(error => {
                setConfirmOpen(false);
                console.log(error);
            });
    }

    const handleClose = () => {
        setOpen(false);
    }

    const getTableColumns = () => {
        let columns = TableFunctions.getTableColumns(logs);
        columns.push({
            name: 'Delete',
            selector: row => <Button variant="contained" color="error" onClick={() => confirmDelete(row)}>X</Button>
        });
        return columns;
    }

    const rowsForDisplay = () => {
        return logs.map(log => {
            return {
                ... log,
                user: log.user.name,
                date: log.date.substring(0, 10),

            }
        });
    }

    return (
        <>
            {logs.length > 0 &&
                <DataTable
                    columns={getTableColumns()}
                    data={rowsForDisplay()}
                    highlightOnHover
                    pointerOnHover
                    onRowClicked={rowClick}
                />
            }
            <Dialog open={open} fullWidth={true} maxWidth={'sm'} onClose={handleClose}>
                <DialogTitle>Log Details</DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%'
                    }}>
                    <UserSelect 
                        value={selectedLog.user_id} 
                        onChange={(val) => setSelectedLog( { ...selectedLog, user_id: val } )} 
                        name="user" 
                        label="User" 
                    />
                    <DateField
                        label="Date"
                        value={selectedLog.date}
                        onChange={(val) => setSelectedLog( { ...selectedLog, date: val } )}
                    />
                    <TextField 
                        id="message" 
                        label="Message" 
                        variant="filled" 
                        value={selectedLog.message} 
                        onChange={(e) => setSelectedLog( { ...selectedLog,  message: e.target.value } )}
                        sx={{ marginBottom: 1 }}
                        multiline
                        rows={4}
                    />
                    <TextField 
                        id="time_worked" 
                        label="Time Worked" 
                        variant="filled" 
                        value={selectedLog.time_worked} 
                        onChange={(e) => setSelectedLog( { ...selectedLog,  time_worked: e.target.value } )}
                        sx={{ marginBottom: 1 }}
                        type="number"
                    />
                    <Button variant="contained" color="secondary" onClick={saveLog}>Save</Button>
                </DialogContent>
            </Dialog>
            <Button sx={{ marginTop: 2, float: 'right' }} onClick={() => setOpen(true)} variant="outlined">Add Log</Button>
            <ConfirmationModal 
                open={confirmOpen} 
                message={"Are your sure you'd like to delete this log? This action can't be undone."} 
                confirmed={deleteLog}
                cancelled={cancelDelete}
            />
        </>
    );

}

export default LogsTable;