import React from 'react';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';

// Utilities
import TableFunctions  from '../../utilities/table-functions';

const ProjectsTable = ({ projects, fetchFunc }) => {

    const navigate = useNavigate();

    const rowClick = ( row ) => {
        // Navigate to the single project page
        navigate(`/project/${row.id}/tasks`);
    }

    const sortable = [
        'Name',
        'Account Manager',
        'Budget',
        'Rate',
        'Type',
    ];

    const rowsForDisplay = () => {
        return projects.data.map( project => {
            return {
                ...project,
                account_manager: project.account_manager ? project.account_manager.name : ''
            }
        });
    }

    const changePage = ( page ) => {
        fetchFunc({ page });
    }

    const handleSort = async ( column, sortDirection ) => {
        fetchFunc({
            'page': projects.current_page, 
            'sort': column.sortField, 
            'order': sortDirection
        });
    };

    return( 
        <DataTable
            columns={TableFunctions.getTableColumns(projects.data, sortable)}
            data={rowsForDisplay(projects.data)}
            highlightOnHover
            pointerOnHover
            onRowClicked={rowClick}
            sortServer
            onSort={handleSort}
            pagination={projects.last_page > 1}
            paginationServer
            paginationTotalRows={projects.total}
            onChangePage={changePage}
            paginationPerPage={projects.per_page}
        />
    );

}

export default ProjectsTable;