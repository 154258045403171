import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

// Components
import UserSelect from '../global/user-select';
import CommentsDisplay from './comments';
import FileUploader from '../global/file-uploader';
import TextEditor from '../global/text-editor';
import DateField from '../global/date-field';
import ConfirmationModal from '../global/confirmation-modal';

// Material Components
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MaterialSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Delete Icon
import DeleteIcon from '@mui/icons-material/Delete';

// Toast
import { toast } from 'react-toastify';

const TaskSingle = () => {

    const navigate = useNavigate();

    const { taskId, projectId } = useParams();
    const [task, setTask] = useState({
        id: null,
        name: '',
        description: '',
        estimated_time: 0,
        time_worked: 0,
        due_date: null,
        priority: 2,
        status: 'Approved',
        assigned_to: null,
        project: {}
    });

    const [saved, setSaved] = useState(false);
    const priorityOptions = [
        { id: 1, name: 'Low' },
        { id: 2, name: 'Medium' },
        { id: 3, name: 'High' },
        { id: 4, name: 'Critical' }
    ];
    const statusOptions = [
        'Approved',
        'In Progress',
        'On Hold',
        'Completed',
    ];

    const [confirmOpen, setConfirmOpen] = useState(false);

    useEffect(() => {
        if( taskId ){
            getTask();
        }
        if( projectId ){
            setTask( { ...task, project: { ... task.project, id: projectId } } );
        }
    }, []);

    useEffect(() => {
        if( projectId ){
            getProjectName();
        }
    }, [ projectId] );

    // Trigger Reload after New save
    useEffect(() => {
        if( taskId ){
            getTask();
        }
    }, [ taskId ]);

    // update immediately on change of certain props
    useEffect(() => {
        if ( taskLoaded.current ) {
            updateTask();
        }
    }, [
        task.due_date,
        task.priority,
        task.status,
        task.assigned_to,
    ]);

    useEffect(() => {
        if ( taskLoaded.current ) {
            textUpdated.current = true;
            window.addEventListener('beforeunload', updateTask);
        }
        return () => {
            window.removeEventListener('beforeunload', updateTask)
        };
    }, [
        task.name,
        task.description,
        task.estimated_time,
    ]);

    const textUpdated = useRef( false );
    const textBlurred = () => {
        if ( textUpdated.current ) {
            updateTask();
        }
    }

    // used as a flag for other obj because this triggers AFTER prop updates,
    // ie this allows other useEffect hooks to check whether they are on change to an existing prop or simply the first setting on task load
    const taskLoaded = useRef(false);
    useEffect(() => {
        if ( task.id ) {
            taskLoaded.current = true;
        }
    }, [ task ] );

    const getTask = () => {
        axios.get(`/task/${taskId}`)
        .then(response => {
            setTask(response.data);
        })
        .catch(error => {
            toast.error( error );
        });
    }

    const toLogs = () => {
        navigate(`/task/${taskId}/logs`);
    }

    const saveTask = () => {
        if( task.id ){
            updateTask();
        }
        else{
            createTask();
        }    
    }

    const updateTask = () => {
        if ( ! task.id ) {
            return;
        }
        window.removeEventListener('beforeunload', updateTask)
        const pushTask = { ...task, project_id: task.project.id };
        axios.post(`/task/${taskId}/update`, pushTask)
            .then(response => {
                if( response.data.error ){
                    toast.error( response.data.error );
                }
                else{
                    toast.success( 'Task Saved' );
                    // remove after updating files
                    setSaved(true);
                    setTimeout(() => {
                        setSaved(false);
                    }, 3000);
                }
            })
            .catch(error => {
                toast.error( error );
            });
    }

    const createTask = () => {
        const pushTask = { ...task, project_id: task.project.id };
        axios.post(`/task/create`, pushTask)
            .then(response => {
                if( response.data.error ){
                    toast.error( response.data.error );
                }
                else{
                    navigate(`/task/${response.data.id}`);
                    // remove after updating files
                    setSaved(true);
                    setTimeout(() => {
                        setSaved(false);
                    }, 3000);
                }
            })
            .catch(error => {
                toast.error( error );
            });
    }

    const getProjectName = () => {
        axios.get(`/project/${projectId}`)
            .then(response => {
                setTask( { ...task, project: { ... task.project, id: projectId, name: response.data.name } } );
            })
            .catch(error => {
                toast.error( error );
            });
    }

    const getProgress = () => {
        return task.estimated_time > 0 && task.estimated_time > task.time_worked ? (task.time_worked / task.estimated_time) * 100 : 100;
    }

    const confirmDelete = () => {
        setConfirmOpen(true);
    }

    const deleteTask = () => {
        axios.post(`/task/${task.id}/delete`)
            .then(response => {
                toast.success( 'Task Deleted' );
                navigate(`/project/${task.project.id}/tasks`);
            })
            .catch(error => {
                toast.error( error );
            });
    }

    return(
        <>
            <Box>
                {(task.id || !taskId) &&
                    <Box
                        maxWidth='sm'
                        sx={{
                            marginTop: 2,
                            padding: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'space-between' }}>
                            {task.project.id &&
                                <>
                                    <Link component="button" variant="body2" onClick={e => navigate(`/project/${task.project.id}/tasks`)}>
                                        {task.project.name}
                                    </Link>
                                    { task.id && 
                                        <Button variant="text" color="error" onClick={confirmDelete}>
                                            <DeleteIcon />
                                        </Button>
                                    }
                                </>
                            }
                        </Box>
                        <h1>{task.id} {task.name}</h1>
                        <TextField 
                            id="name" 
                            label="Name" 
                            variant="filled" 
                            value={task.name} 
                            onChange={(e) => setTask( { ...task,  name: e.target.value } )}
                            sx={{ marginBottom: 1 }}
                            onBlur={ textBlurred }
                        />
                        <TextEditor 
                            initialValue={task.description} 
                            onChange={ (val) => setTask({ ...task, description: val }) }
                            taskId={task.id}
                            onBlur={ textBlurred }
                        />
                        <UserSelect 
                            value={task.assigned_to} 
                            onChange={(val) => setTask( { ...task, assigned_to: val } )} 
                            name="assigned_to" 
                            label="Assigned To" 
                            clearable={true}
                        />
                        <TextField 
                            id="estimated_time" 
                            label="Estimated Time" 
                            variant="filled" 
                            value={task.estimated_time} 
                            onChange={(e) => setTask( { ...task,  estimated_time: e.target.value } )}
                            type='number'
                            sx={{ marginBottom: 1 }}
                            onBlur={ textBlurred }
                        />
                        <DateField 
                            label="Due Date" 
                            value={task.due_date} 
                            onChange={(val) => setTask( { ...task, due_date: val } )} 
                        />
                        <FormControl variant="filled" sx={{ marginBottom: 1 }}>
                            <InputLabel>Priority</InputLabel>
                            <MaterialSelect
                                label="Priority"
                                name="priority"
                                value={task.priority}
                                onChange={(e) => setTask( { ...task,  priority: e.target.value } )}
                                variant="filled"
                            >
                                {priorityOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                ))}
                            </MaterialSelect>
                        </FormControl>
                        <FormControl variant="filled" sx={{ marginBottom: 1 }}>
                            <InputLabel>Status</InputLabel>
                            <MaterialSelect
                                label="Status"
                                name="status"
                                value={task.status}
                                onChange={(e) => setTask( { ...task,  status: e.target.value } )}
                                variant="filled"
                            >
                                {statusOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                ))}
                            </MaterialSelect>
                        </FormControl>
                        {task.id &&
                            <FileUploader triggerUpload={saved} taskId={task.id} />
                        }
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant="determinate" value={getProgress()} color={task.estimated_time > task.time_worked ? 'primary' : 'error'} />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2">{Math.round(task.time_worked * 100) / 100}/{task.estimated_time}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: 1 }}>
                            {task.id &&
                                <Button variant="contained" color="primary" sx={{ marginRight: 1, width: '50%' }} onClick={toLogs}>View Logs</Button>
                            }
                            <Button variant="contained" color="secondary" sx={{ width: '50%' }} onClick={saveTask}>Save</Button>
                        </Box>
                    </Box>
                }
                {task.id &&
                    <Box
                        maxWidth='sm'
                        sx={{
                            marginTop: 2,
                            padding: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <CommentsDisplay taskId={task.id} />
                    </Box>
                }
            </Box>
            <ConfirmationModal 
                open={confirmOpen} 
                message="Are you sure you want to delete this task?" 
                confirmed={deleteTask} 
                cancelled={() => { setConfirmOpen( false ); }} 
            />
        </>
    );

}

export default TaskSingle;