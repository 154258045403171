import React from 'react';

// Material Components
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmationModal = ({ open, message, confirmed, cancelled }) => {

    return (
        <Modal
            open={open}
            onClose={cancelled}
            aria-labelledby="confirmation-modal"
            aria-describedby="confirmation-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                <Typography id="confirmation-modal-description" variant="h6" component="h2">{message}</Typography>
                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={cancelled} sx={{ margin: 1}} color="error">Cancel</Button>
                    <Button variant="contained" onClick={confirmed} sx={{ margin: 1}}>Confirm</Button>
                </Box>
            </Box>
        </Modal>
    );

}

export default ConfirmationModal;