import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Auth Context
import { AuthContext } from '../../utilities/auth-context';

// Components
import TasksTable from './table';
import SearchForm from '../global/search-form';

// Material UI Components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// utils
import enforcePaginatedResponse from '../../utilities/enforce-paginated-response';

const TasksArchive = ({ withUser, assignedByUser }) => {
    
    const [tasks, setTasks]                 = useState({});
    const { projectId, userId }             = useParams();
    const [projectName, setProjectName]     = useState('');
    const { state }                         = useContext(AuthContext);
    const defaultStatus                     = 'Loading...';
    const [status, setStatus]               = useState( defaultStatus );
    const [showCompleted, setShowCompleted] = useState(false);
    const [search, setSearch]               = useState('');
    const defaultSort                       = { column: 'due_date', order: 'asc' };
    const [sort, setSort]                   = useState( defaultSort );
    const [page, setPage]                   = useState( 1 );

    const navigate = useNavigate();

    // because component is reused if navigating directly between routes, eg tasks assigned to/by me
    useEffect(() => {
        setTasks( enforcePaginatedResponse([]) );
        setStatus( defaultStatus );
        setPage( 1 );
        setSort( defaultSort );
    }, [
        withUser,
        assignedByUser,
    ]);

    useEffect(() => {
        fetchTasks();
    }, [
        userId,
        projectId,
        projectName,
        search,
        showCompleted,
        state,
        sort,
        page,
    ]);

    useEffect(() => {
        if ( projectId ) {
            getProject();
        }
    }, [ projectId ]);

    const getProject = () => {
        if( projectId ){
            axios.get(`/project/${projectId}`)
                .then(response => {
                    setProjectName(response.data.name);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const fetchTasks = ( filters = {} ) => {
        let url = getFetchUrl( filters );
        if ( !url ) { return; }
        axios.get( url )
            .then(response => {
                response.data = enforcePaginatedResponse( response.data );
                setTasks(response.data);
                if( response.data.data.length === 0 ){
                    setStatus('No tasks found.');
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    const getFetchUrl = ( filters = {} ) => {
        let url;
        if( projectId ){
            url = `/project/${projectId}/tasks`;
        }
        if( withUser && state.user ){
            url = `/user/${state.user}/tasks`;
        }
        if( assignedByUser && state.user ){
            url = `/user/${state.user}/tasks-assigned`;
        }
        else if( userId ){
            url = `/user/${userId}/tasks`;
        }

        if ( !url ) { return url; }

        let params = new URLSearchParams();

        params.append( 'sort', sort.column );
        params.append( 'order', sort.order );
        params.append( 'page', page );

        if( showCompleted ){
            params.append( 'show_completed', true );
        }
        if( search ){
            params.append( 'search', search );
        }
            
        if ( params.size ) {
            url += `?${ params.toString() }`;
        }

        return url;
    }

    const handleTableChanges = ( args ) => {
        if ( args.page ?? null ) {
            setPage( args.page );
        }
        if ( args.sort ?? null ) {
            setSort( args.sort );
        }
    }

    return (
        <Box sx={{padding: 1, maxWidth: 'calc(100% - 250px);'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <SearchForm runSearch={(term) => setSearch(term)} />
            </Box>
            {withUser &&
                <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <h1>My Tasks</h1>
                    <FormControlLabel
                        control={<Checkbox checked={showCompleted} onChange={e => setShowCompleted(e.target.checked)} />}
                        label="Show Completed Tasks"
                    />
                </Box>
            }
            {assignedByUser &&
                <Box sx={{display: 'flex', marginTop: 2 }}>
                    <h1>Tasks That I Have Assigned</h1>
                </Box>
            }
            {userId &&
                <>
                    <Button sx={{ margin: 1 }} onClick={() => navigate(`/users`)} variant="outlined">Back to Users</Button>
                        {(tasks.hasOwnProperty('data') && tasks.data.length > 0) && 
                            <h1>Tasks Assigned to {tasks.data[0].assigned_to.name}</h1>
                        }
                </>
            }
            {projectId &&
                <div>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                        <Link component="button" variant="body2" onClick={e => navigate('/projects')}>
                            Back to Projects
                        </Link>
                    </Box>
                    <Button sx={{ margin: 1, float: 'right' }} onClick={e => navigate(`/project/${projectId}`)} variant="outlined">Settings</Button>
                    <Button sx={{ margin: 1, float: 'right' }} onClick={() => navigate(`/project/${projectId}/newtask`)} variant="outlined">Add Task</Button>
                </div>
            }
            {projectName &&
                <Box>
                    <h1>{projectName} Tasks</h1>
                    <FormControlLabel
                        control={<Checkbox checked={showCompleted} onChange={e => setShowCompleted(e.target.checked)} />}
                        label="Show Completed Tasks"
                    />
                </Box>
            }
            {(tasks.hasOwnProperty('data') && tasks.data.length > 0)
                ? <TasksTable
                    tasks={tasks}
                    changeHandler={handleTableChanges}
                    showAssigned={(!withUser && !userId)}
                    defaultSort={sort}
                    />
                : <p>{status}</p>
            }
        </Box>
    );
}

export default TasksArchive;