import React from 'react';

// Material Components
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Icons
import SearchIcon from '@mui/icons-material/Search';

const SearchForm = ({ runSearch }) => {

    const handleKeyPress = (e) => {
        if( e.key === 'Enter' ){
            validateSearch();
        }
    }

    const validateSearch = () => {
        let search = document.getElementById('search').value;
        // if( search.length > 0 ){
        //     runSearch(search);
        // }
        // Actually want the ability to clear
        runSearch( search );
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField 
                id="search" 
                label="Search"
                variant="filled"
                sx={{ padding: '0px' }}
                inputProps={{
                    style: {
                      
                    }
                }}
                onKeyPress={handleKeyPress}
                />
            <Button 
                sx={{ marginLeft: 1, marginRight: 1, height: '56px'}} 
                variant="outlined"
                onClick={validateSearch}
            >
                <SearchIcon />
            </Button>
        </Box>
    );

}

export default SearchForm;