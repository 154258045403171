import React, { useEffect, useState, useContext } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

// Auth Context
import { AuthProvider, AuthContext, initiateXSRFToken, checkAuth } from '../utilities/auth-context';

// Components
import CheckAuth from './auth/check-auth';
import Sidebar from './global/sidebar';
import Login from './auth/login';
import ProjectsArchive from './projects/archive';
import ProjectSingle from './projects/single';
import TasksArchive from './tasks/archive';
import TaskSingle from './tasks/single';
import LogsArchive from './logs/archive';
import UsersArchive from './users/archive';

// Material Components
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

// Toasts
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#78c237',
        },
        secondary: {
            main: '#8d288e',
        },
    },
});

const MainRouter = () => {

    const [authenticated, setAuthenticated] = useState(false);

    // useEffect(() => {
    //     console.log( authenticated );
    // }, [ authenticated ]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Container component="main" maxWidth="lg" style={{padding: 0}}>
                <div id='simplistics-pm-dashboard'>
                    <AuthProvider>
                        <CheckAuth onAuthenticate={() => { setAuthenticated(true) }} />
                        <HashRouter>
                            <div style={{display: 'flex'}}>
                                {authenticated && <Sidebar onLogout={() => setAuthenticated(false)} />}
                                <Routes>

                                    <Route path="/login" element={<Login onAuthenticate={ () => { setAuthenticated(true) }}/>} />

                                    <Route path ="/project/:projectId" element={<ProjectSingle />} />
                                    <Route path="/project/new" element={<ProjectSingle />} />
                                    <Route path="/projects" element={<ProjectsArchive />} />
                                    <Route path ="/project/:projectId/tasks" element={<TasksArchive />} />

                                    <Route path="/task/:taskId" element={<TaskSingle />} />
                                    <Route path="/my-tasks" element={<TasksArchive withUser={true} />} />
                                    <Route path="/my-assigned-tasks" element={<TasksArchive assignedByUser={true} />} />
                                    <Route path="/all-tasks" element={<TasksArchive />} />
                                    <Route path="/project/:projectId/newtask" element={<TaskSingle />} />

                                    <Route path='/users' element={<UsersArchive />} />
                                    <Route path='/user/:userId/tasks' element={<TasksArchive />} />

                                    <Route path="/task/:taskId/logs" element={<LogsArchive />} />

                                </Routes>
                            </div>
                        </HashRouter>
                    </AuthProvider>
                </div>
            </Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                newestOnTop={false}
            />
        </ThemeProvider>
    );

}

export default MainRouter;