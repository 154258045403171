// transform archive response to ensure that it has required props for pagination
// allows changing back end to return paginated or not and have the front end treat unpaginated as one big page

export default function ( data ) {
  if ( Array.isArray( data ) ) {
    return {
      data: data,
      current_page: 1,
      last_page: 1,
      per_page: data.length,
      from: 1,
      to: data.length,
    }
  }
  return data;
}