import React from 'react';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';

// Utilities
import TableFunctions  from '../../utilities/table-functions';
import { DateTime } from 'luxon';

// Material UI Components
import Box from '@mui/material/Box';

const TasksTable = ({ tasks, changeHandler, showAssigned, defaultSort = {} }) => {

    const navigate = useNavigate();
    const rowClick = ( row ) => {
        // Navigate to the single task page
        navigate(`/task/${row.id}`);
    }

    const priorityOptions = [
        { id: 1, name: 'Low' },
        { id: 2, name: 'Medium' },
        { id: 3, name: 'High' },
        { id: 4, name: 'Critical' }
    ];

    const sortable = [
        'Priority',
        'Due Date',
        'Assigned To',
        'Project',
        'Status'
    ];

    const rowsForDisplay = () => {
        return tasks.data.map( task => {
            delete task.description;
            return {
                ...task,
                priority: task.priority ? priorityOptions.find( option => option.id === task.priority ).name : '--',
                assigned_to: task.assigned_to ? task.assigned_to.name : '--',
                project: task.project ? task.project.name : '--',
            }
        });
    }

    const getColumns = () => {
        let columns = TableFunctions.getTableColumns(tasks.data, sortable);
        if( !showAssigned ){
            // remove assigned_to column
            columns = columns.filter( column => column.name !== 'Assigned To' );
        }
        columns = TableFunctions.reOrderColumn(columns, 'Project', 1);

        // Combine Time Columns
        columns = columns.filter( column => column.name !== 'Estimated Time' && column.name !== 'Time Worked' );
        columns.push({
            name: 'Time',
            selector: row => `${Math.round(row.time_worked * 100 ) / 100} / ${row.estimated_time ? row.estimated_time : '--'}`
        });

        // format due date
        const today = DateTime.now().startOf( 'day' );
        columns = columns.map( c => {
            if ( c.sortField == 'due_date' ) {
                c.selector = row => {
                    const dstring = row[ c.sortField ];
                    if ( dstring ) {
                        const dt = DateTime.fromISO( dstring );
                        const daysFromNow = dt.diff( today, 'days' );
                        if ( ! daysFromNow.invalid ) {
                            switch ( daysFromNow.values.days ) {
                                case -1:
                                    return 'Yesterday';
                                case 0:
                                    return 'Today';
                                case 1:
                                    return 'Tomorrow';
                                default:
                                    if ( 0 < daysFromNow.values.days && daysFromNow.values.days < 7 ) {
                                        return dt.toFormat( 'EEEE' );
                                    }
                            }
                        }
                    }
                    return dstring;
                }
            }
            return c;
        });

        return columns;
    }

    const handleSort = async ( column, sortDirection ) => {
        changeHandler({
            page: tasks.current_page, 
            sort: {
                column: column.sortField, 
                order: sortDirection
            }
        });
    };

    const changePage = ( page ) => {
        changeHandler({ page });
    }

    return(
        <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            <DataTable
                columns={getColumns()}
                data={rowsForDisplay()}
                highlightOnHover
                pointerOnHover
                onRowClicked={rowClick}
                sortServer
                onSort={handleSort}
                defaultSortFieldId={ defaultSort.column }
                defaultSortAsc={ defaultSort.order == 'asc' }
                pagination={ tasks.last_page > 1 }
                paginationServer
                paginationTotalRows={tasks.total}
                onChangePage={changePage}
                paginationPerPage={tasks.per_page}
            />
        </Box>
    );

}

export default TasksTable;